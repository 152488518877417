<template>
  <div>
    <b-row class="m-0 justify-content-center">
      <b-col class="content-header" cols="9">
        <h2 class="content-header-title float-left m-0 pr-1 mr-1">
          {{ $route.meta.pageTitle }}
        </h2>
        <div class="content-header-search mr-1"></div>
        <div class="content-header-actions d-flex">
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="24"
            @click="cancel()"
          />
        </div>
      </b-col>
    </b-row>
    <div
      v-if="isLoadingPayment || isCreatingPayment || isUpdatingPayment"
      class="content-loader-center m-0 h-100"
    >
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement des détails du paiement...
        </div>
      </div>
    </div>
    <b-row
      v-else
      class="content-scrollable-sticky m-0 h-100 justify-content-center"
    >
      <b-col cols="9" class="content-scrollable-sticky-main">
        <validation-observer id="formCreatePayment" ref="formCreatePayment">
          <b-form autocomplete="off">
            <div id="general" class="my-0">
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group label="Client *" label-for="customer">
                    <validation-provider
                      #default="{ errors }"
                      name="Customer"
                      rules="required"
                    >
                      <v-select
                        disabled
                        id="customer"
                        :loading="isLoadingCompany"
                        :state="errors.length > 0 ? false : null"
                        v-model="paymentForm.companyId"
                        :reduce="(el) => el.value"
                        @input="affectCompanyName"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="
                          companiesListByNature.map((elem) => {
                            return { label: elem.name, value: elem.id };
                          })
                        "
                      >
                        <template v-slot:no-options>
                          <template> Aucun résultat trouvé </template>
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group label="Date du règlement *" label-for="date">
                    <validation-provider
                      #default="{ errors }"
                      name="date"
                      rules="required"
                    >
                      <date-range-picker
                        :disabled="paymentForm.id != 0"
                        :showDropdowns="true"
                        ref="picker"
                        id="dates"
                        style="width: 100%"
                        :show-week-numbers="true"
                        :timePicker="false"
                        :single-date-picker="true"
                        :opens="'right'"
                        :append-to-body="true"
                        :locale-data="config"
                        :ranges="defaultRange"
                        v-model="datePicker"
                        :auto-apply="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group
                    label="Moyen de paiement *"
                    label-for="paymentType"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="paymentType"
                      rules="required"
                    >
                      <v-select
                        :disabled="paymentForm.id != 0"
                        :deselectFromDropdown="true"
                        id="paymentType"
                        :loading="isLoadingPaymentMethodsList"
                        :state="errors.length > 0 ? false : null"
                        v-model="paymentForm.paymentMethodId"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="paymentMethodsList"
                        :reduce="(elem) => elem.id"
                      >
                        <template v-slot:no-options>
                          <template> Aucun résultat trouvé </template>
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <b-form-group
                    label="Montant du règlement (€)*"
                    label-for="amount"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="amount"
                      rules="required"
                    >
                      <b-form-input
                        id="amount"
                        v-model="paymentForm.amount"
                        :state="errors.length > 0 ? false : null"
                        class="not-autocomplete"
                        type="number"
                        @blur="checkNumber"
                        autocomplete="nope"
                        aria-autocomplete="nope"
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- LISTE DES DOCUMENTS (FACTURE, AVOIR) DEJA LIES -->
              <b-row
                v-if="paymentForm.invoices && paymentForm.invoices.length > 0"
                class="my-0"
              >
                <b-col style="margin-bottom: 5px" cols="12" class="py-0">
                  <span
                    >Règlement lié
                    {{
                      paymentForm.invoices.length == 1
                        ? "à la facture suivante :"
                        : "aux factures suivantes :"
                    }}</span
                  >
                </b-col>
                <b-col cols="12" class="py-0 mb-1">
                  <ul>
                    <li
                      v-for="(item, index) in paymentForm.invoices"
                      :key="index"
                    >
                      Facture n°{{ item.documentReference }} pour
                      {{ formatCurrency(item.value) }} TTC sur
                      {{ formatCurrency(item.totalTtc) }} TTC
                    </li>
                  </ul>
                </b-col>
              </b-row>
              <b-row
                v-if="paymentForm.credits && paymentForm.credits.length > 0"
                class="my-0"
              >
                <b-col style="margin-bottom: 5px" cols="12" class="py-0">
                  <span
                    >Règlement lié
                    {{
                      paymentForm.credits.length == 1
                        ? `à l'avoir suivant :`
                        : `aux avoirs suivants :`
                    }}</span
                  >
                </b-col>
                <b-col cols="12" class="py-0 mb-1">
                  <ul class="pl-1">
                    <li
                      v-for="(item, index) in paymentForm.credits"
                      :key="index"
                    >
                      Avoir n°{{ item.documentReference }} pour
                      {{ formatCurrency(item.value) }} TTC sur
                      {{ formatCurrency(item.totalTtc) }} TTC
                    </li>
                  </ul>
                </b-col>
              </b-row>
              <div>
                <b-row class="my-0 mt-2">
                  <b-col
                    :style="remainsToBeDeducted < 0 ? 'color:red' : ''"
                    class="py-0"
                    cols="12"
                  >
                    <h4>Répartition du règlement</h4>
                    <p>
                      Reste à répartir :
                      {{ formatCurrency(remainsToBeDeducted) }}
                    </p>
                  </b-col>
                </b-row>
                <b-col :key="key" class="array-distribution-payment">
                  <b-row class="array-header">
                    <b-col class="array-cell" cols="3">
                      <span>Document</span>
                    </b-col>
                    <b-col class="array-cell" cols="3">
                      <span>Client</span>
                    </b-col>
                    <b-col class="array-cell" cols="2">
                      <span>Total TTC</span>
                    </b-col>
                    <b-col class="array-cell" cols="2">
                      <span>Reste à solder</span>
                    </b-col>
                    <!-- <b-col class="array-cell" cols="2">
                      <span>Montant à déduire</span>
                    </b-col> -->
                  </b-row>
                  <b-row
                    v-if="documentsSelected.length > 0"
                    class="array-content"
                    v-for="(item, index) in documentsSelected"
                    :key="index"
                  >
                    <b-col class="array-cell" cols="3">
                      <span>
                        Retenue de garantie -
                        {{ natureShortTranslate(item.paymentNature) }}
                        {{ item.documentReference }}</span
                      >
                    </b-col>
                    <b-col class="array-cell" cols="3">
                      <span>{{ item.companyName }}</span
                      ><br />
                      <span>{{ item.affairName ? item.affairName : "" }}</span>
                    </b-col>
                    <b-col class="array-cell" cols="2">
                      <span>{{ formatCurrency(item.totalTtc) }}</span>
                    </b-col>
                    <b-col class="array-cell" cols="2">
                      <span>{{ formatCurrency(item.remainingToPayTtc) }}</span>
                    </b-col>
                    <!-- <b-col class="array-cell" cols="2">
                      <validation-provider name="paymentDistribution">
                        <input
                          @blur="
                            deductRemainsToBeDeducted(
                              index,
                              item.paymentDistribution
                            )
                          "
                          type="number"
                          class="mb-0"
                          id="paymentDistribution"
                          v-model="item.paymentDistribution"
                        />
                      </validation-provider>
                    </b-col> -->
                  </b-row>
                  <b-row
                    v-if="documentsSelected.length == 0"
                    class="p-1 justify-content-center d-flex"
                  >
                    <span>Aucun document séléctionné pour la répartition</span>
                  </b-row>
                </b-col>
              </div>
              <b-row class="my-0">
                <b-col cols="12" class="py-0 mb-1">
                  <small
                    class="cursor-pointer text-primary"
                    @click="displayMore = !displayMore"
                    >{{
                      displayMore
                        ? "- Masquer les informations complémentaires"
                        : "+ Afficher les informations complémentaires"
                    }}
                  </small>
                </b-col>
              </b-row>
              <div v-if="displayMore">
                <b-row class="my-0">
                  <b-col cols="12" class="py-0 mb-1">
                    <b-form-group label="Référence" label-for="reference">
                      <validation-provider
                        #default="{ errors }"
                        name="reference"
                      >
                        <b-form-input
                          id="reference"
                          v-model="paymentForm.reference"
                          :state="errors.length > 0 ? false : null"
                          class="not-autocomplete"
                          autocomplete="nope"
                          aria-autocomplete="nope"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="my-0">
                  <b-col cols="12" class="py-0 mb-1">
                    <b-form-group label="Notes" label-for="notes">
                      <validation-provider #default="{ errors }" name="notes">
                        <b-form-textarea
                          id="notes"
                          v-model="paymentForm.notes"
                          :state="errors.length > 0 ? false : null"
                          rows="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="my-0">
                  <b-col cols="12" class="py-0 mb-1">
                    <b-form-group label-for="notes">
                      <validation-provider #default="{ errors }" name="notes">
                        <b-form-checkbox
                          v-model="paymentForm.remittedBank"
                          name="check-button"
                          switch
                          inline
                        >
                          Ce règlement a été enregistré en banque
                        </b-form-checkbox>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="my-0">
                  <b-col cols="12" class="py-0 mb-1">
                    <b-form-group
                      label="Date de remise en banque"
                      label-for="remittedBankDate"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="remittedBankDate"
                      >
                        <date-range-picker
                          :disabled="!paymentForm.remittedBank"
                          :showDropdowns="true"
                          ref="picker"
                          id="remittedBankDate"
                          style="width: 100%; background-color: #f8f8f8"
                          :show-week-numbers="true"
                          :timePicker="false"
                          :single-date-picker="true"
                          :opens="'right'"
                          :append-to-body="true"
                          :locale-data="config"
                          :ranges="defaultRange"
                          v-model="datePickerRemittedBankDate"
                          :auto-apply="true"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="my-0">
                  <b-col cols="12" class="py-0 mb-1">
                    <b-form-group
                      label="Compte bancaire "
                      label-for="bankAccountId"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="bankAccountId"
                      >
                        <v-select
                          :deselectFromDropdown="true"
                          id="bankAccountId"
                          :loading="isLoadingBankAccountsList"
                          :state="errors.length > 0 ? false : null"
                          v-model="paymentForm.bankAccountId"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="
                            bankAccountsList.map((elem) => {
                              const newLabel = elem.label + ' ' + elem.iban;
                              return { label: newLabel, value: elem.id };
                            })
                          "
                          :reduce="(elem) => elem.value"
                          :disabled="!paymentForm.remittedBank"
                        >
                          <template v-slot:no-options>
                            <template> Aucun résultat trouvé </template>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-form>
        </validation-observer>
        <b-row class="my-0">
          <b-col cols="12" class="py-0">
            <div
              class="d-flex justify-content-between mt-2"
              style="border-top: 1px solid #e2e2e2; padding-top: 0.5rem"
            >
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  @click="cancel()"
                >
                  <feather-icon icon="ArrowLeftIcon" />
                  Annuler
                </b-button>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="create"
              >
                <feather-icon icon="SaveIcon" v-if="paymentForm.id" />
                <feather-icon icon="PlusIcon" v-else />
                {{ paymentForm.id ? "Modifier" : "Ajouter" }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { debounce } from "lodash";
import Autocomplete from "vuejs-auto-complete";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { BFormRadio } from "bootstrap-vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { natureShortTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);
const config = {
  direction: "ltr",
  format: "dd/mm/yyyy",
  separator: " - ",
  applyLabel: "Appliquer",
  cancelLabel: "Annuler",
  weekLabel: "S",
  customRangeLabel: "Période personnalisée",
  daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  firstDay: 1,
};
var defaultRange = {
  "Aujourd'hui": [new Date(dayjs()), new Date(dayjs())],
  Demain: [new Date(dayjs().add(1, "day")), new Date(dayjs().add(1, "day"))],
  Hier: [
    new Date(dayjs().subtract(1, "day")),
    new Date(dayjs().subtract(1, "day")),
  ],
  "Dans 1 mois": [
    new Date(dayjs().add(1, "month")),
    new Date(dayjs().add(1, "month")),
  ],
};
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required } from "@validations";

configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      required: "Ce champ est requis",
    },
  }),
});
localize("fr");

import { mapGetters, mapActions } from "vuex";
import { BFormCheckbox } from "bootstrap-vue";
import { mask } from "vue-the-mask";

export default {
  props: {
    id: {
      default: null,
    },
    disabled: {
      default: false,
    },
    documentData: {
      default: null,
    },
  },
  data() {
    return {
      required,
      dataOrigine: {},
      paymentForm: {},
      defaultRange: defaultRange,
      config: config,
      displayMore: false,
      filter: false,
      documentsSelected: [],
      remainsToBeDeducted: 0,
      initialRemainsToBeDeducted: 0,
      documentListForDeduction: [],
      selectInstitution: null,
      key: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  created() {
    this.popupPaymentEvent(this.id);
    this.getCompanies({});
    this.getPaymentMethodByWorkspaceId({});
  },
  methods: {
    ...mapActions([
      "getPaymentById",
      "getHoldbackById",
      "getCompanies",
      "getPaymentMethodByWorkspaceId",
      "getBankAccounts",
      "createPayment",
      "updatePayment",
      "getInvoicesByCompanyIds",
      "getCreditsByCompanyIds",
      "updateInvoicePayment",
      "updateCreditPayment",
      "updateHoldbackPayment",
    ]),
    formatCurrency,
    natureShortTranslate,
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(
        ".content-scrollable-sticky-nav ul li a"
      );
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach((link) => {
        let section = document.querySelector(link.hash);
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    changeType(input) {
      this.paymentForm.paymentNature = input;
      this.fetchDocuments();
    },
    fetchDocuments() {
      this.documentsSelected = [];
      if (this.paymentForm.companyId && this.selectInstitution) {
        this.getBankAccounts({});
        if (
          this.paymentForm.paymentType == 0 &&
          this.paymentForm.paymentNature == 1
        ) {
          this.getCreditsByCompanyIds({
            companyIds: [this.paymentForm.companyId],
            status: [2, 7],
          }).then((res) => {
            this.documentListForDeduction = res;
          });
        } else if (
          this.paymentForm.paymentType == 1 &&
          this.paymentForm.paymentNature == 0
        ) {
          this.getInvoicesByCompanyIds({
            companyIds: [this.paymentForm.companyId],
            status: [2, 7],
          }).then((res) => {
            this.documentListForDeduction = res;
          });
        }
      }
    },
    affectCompanyName(val) {
      if (val) {
        let company = this.companiesList.find((el) => el.id == val);
        this.paymentForm.companyName = company.name;
        this.fetchDocuments();
      } else {
        this.paymentForm.companyName = null;
      }
    },
    async popupPaymentEvent(id) {
      if (id != "" && id !== null && id != 0) {
        this.getPaymentById({
          paymentId: id,
        }).then((res) => {
          this.paymentForm = res;
          this.paymentForm.bankAccountId = res.bankAccount
            ? res.bankAccount.id
            : null;
          this.paymentForm.paymentMethodId = res.paymentMethod
            ? res.paymentMethod.id
            : null;
          this.dataOrigine = JSON.stringify(this.paymentForm);
          this.initialRemainsToBeDeducted =
            this.remainsToBeDeducted =
            this.paymentForm.remainsToBeDeducted =
              structuredClone(res.remainsToBeDeducted);
          this.selectInstitution = this.institutionsList.find(
            (el) => el.id == res.institutionId
          );
          this.fetchDocuments();
        });
      } else {
        if (this.documentData != null) {
          await this.initializeFormWithDocumentData();
        } else {
          this.initializeForm();
        }
      }
    },
    initializeForm() {
      this.selectInstitution = this.institutionSelected;
      this.documentsSelected = [];
      this.paymentForm = {
        id: 0,
        amount: 0,
        date: new Date().toISOString(),
        notes: null,
        reference: null,
        remainsToBeDeducted: 0,
        remittedBank: false,
        remittedBankDate: null,
        paymentType: 1,
        bankAccountId: null,
        paymentMethodId: null,
        companyId: null,
        companyName: null,
        institutionId: this.institutionSelected.id,
        paymentNature: 0,
      };
      this.dataOrigine = JSON.stringify(this.paymentForm);
    },
    initializeFormWithDocumentData() {
      let documentData = structuredClone(this.documentData);
      this.documentsSelected = [
        {
          ...documentData,
          paymentDistribution: documentData.remainingToPayTtc,
        },
      ];
      this.paymentForm = {
        id: 0,
        amount: documentData.totalTtc,
        date: new Date().toISOString(),
        notes: null,
        reference: null,
        remainsToBeDeducted: documentData.remainingToPayTtc,
        remittedBank: false,
        remittedBankDate: null,
        paymentType:
          documentData.nature == 5 || documentData.nature == 6 ? 0 : 1,
        paymentMethodId: null,
        companyId: documentData.companyId,
        companyName: documentData.companyName,
        institutionId: this.institutionSelected.id,
        paymentNature:
          documentData.nature == 5 || documentData.nature == 6 ? 1 : 0,
      };
      this.selectInstitution = {
        id: documentData.institutionId,
        name: documentData.institutionName,
      };
      this.dataOrigine = JSON.stringify(this.paymentForm);
      this.getBankAccounts({});
    },
    create(e) {
      e.preventDefault();
      this.$refs.formCreatePayment
        .validate()
        .then((success1) => {
          if (success1 && this.remainsToBeDeducted >= 0) {
            if (this.paymentForm.id) {
              this.updatePayment({
                payment: this.paymentForm,
                remainsToBeDeducted: this.remainsToBeDeducted,
              }).then((res) => {
                if (
                  this.documentsSelected.length > 0 &&
                  this.paymentForm.paymentType == 0
                ) {
                  let credits = Object.fromEntries(
                    this.documentsSelected.map((item) => [
                      item.id,
                      item.paymentDistribution,
                    ])
                  );
                  this.updateCreditPayment({
                    paymentId: res.id,
                    credits: credits,
                  }).then(() => {
                    this.$nextTick(() => {
                      if (
                        this.$route.params.routeOrigine &&
                        this.$route.params.routeOrigine !== ""
                      ) {
                        this.$tabs.close();
                      } else {
                        this.$tabs.close({ to: "/payments" });
                      }
                    });
                  });
                } else if (
                  this.documentsSelected.length > 0 &&
                  this.paymentForm.paymentType == 1
                ) {
                  let holdbacks = Object.fromEntries(
                    this.documentsSelected.map((item) => [
                      item.id,
                      item.paymentDistribution,
                    ])
                  );
                  this.updateHoldbackPayment({
                    paymentId: res.id,
                    holdbacks,
                    holdbacks,
                  }).then(() => {
                    this.$nextTick(() => {
                      if (
                        this.$route.params.routeOrigine &&
                        this.$route.params.routeOrigine !== ""
                      ) {
                        this.$tabs.close();
                      } else {
                        this.$tabs.close({ to: "/payments" });
                      }
                    });
                  });
                } else {
                  this.$nextTick(() => {
                    if (
                      this.$route.params.routeOrigine &&
                      this.$route.params.routeOrigine !== ""
                    ) {
                      this.$tabs.close();
                    } else {
                      this.$tabs.close({ to: "/payments" });
                    }
                  });
                }
              });
            } else {
              this.createPayment({
                payment: this.paymentForm,
                remainsToBeDeducted: this.remainsToBeDeducted,
              }).then((res) => {
                if (
                  this.documentsSelected.length > 0 &&
                  this.paymentForm.paymentType == 0
                ) {
                  let credits = Object.fromEntries(
                    this.documentsSelected.map((item) => [
                      item.id,
                      item.paymentDistribution,
                    ])
                  );
                  this.updateCreditPayment({
                    paymentId: res.id,
                    credits: credits,
                  }).then(() => {
                    this.$nextTick(() => {
                      if (
                        this.$route.params.routeOrigine &&
                        this.$route.params.routeOrigine !== ""
                      ) {
                        this.$tabs.close();
                      } else {
                        this.$tabs.close({ to: "/payments" });
                      }
                    });
                  });
                } else if (
                  this.documentsSelected.length > 0 &&
                  this.paymentForm.paymentType == 1
                ) {
                  let holdbacks = Object.fromEntries(
                    this.documentsSelected.map((item) => [
                      item.id,
                      item.paymentDistribution,
                    ])
                  );
                  this.updateHoldbackPayment({
                    paymentId: res.id,
                    holdbacks: holdbacks,
                  }).then(() => {
                    this.$nextTick(() => {
                      if (
                        this.$route.params.routeOrigine &&
                        this.$route.params.routeOrigine !== ""
                      ) {
                        this.$tabs.close();
                      } else {
                        this.$tabs.close({ to: "/payments" });
                      }
                    });
                  });
                } else {
                  this.$nextTick(() => {
                    if (
                      this.$route.params.routeOrigine &&
                      this.$route.params.routeOrigine !== ""
                    ) {
                      this.$tabs.close();
                    } else {
                      this.$tabs.close({ to: "/payments" });
                    }
                  });
                }
              });
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    cancel() {
      if (this.dataOrigine == JSON.stringify(this.paymentForm)) {
        this.$tabs.close();
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.$tabs.close();
            }
          });
      }
    },
    async checkNumber(res) {
      if (res.target.value < 0) {
        this.initialRemainsToBeDeducted =
          this.paymentForm.remainsToBeDeducted =
          this.paymentForm.amount =
          this.remainsToBeDeducted =
            0;
      } else {
        this.initialRemainsToBeDeducted =
          this.paymentForm.remainsToBeDeducted =
          this.paymentForm.amount =
          this.remainsToBeDeducted =
            Math.round(res.target.value * 100) / 100;
      }
      await this.autoDistribution();
    },
    autoDistribution() {
      let alreadyDeducted = 0;
      for (let i = 0; i < this.documentsSelected.length; i++) {
        const document = this.documentsSelected[i];
        let calc =
          Math.round(
            (this.initialRemainsToBeDeducted -
              document.remainingToPayTtc -
              alreadyDeducted) *
              100
          ) / 100;
        if (calc >= 0) {
          this.documentsSelected[i].paymentDistribution =
            this.documentsSelected[i].remainingToPayTtc;
          this.remainsToBeDeducted =
            Math.round(
              (this.initialRemainsToBeDeducted -
                document.remainingToPayTtc -
                alreadyDeducted) *
                100
            ) / 100;
          alreadyDeducted +=
            Math.round(this.documentsSelected[i].paymentDistribution * 100) /
            100;
        } else {
          this.remainsToBeDeducted = 0;
          this.documentsSelected[i].paymentDistribution =
            Math.round(
              (this.initialRemainsToBeDeducted - alreadyDeducted) * 100
            ) / 100;
          alreadyDeducted +=
            Math.round(this.documentsSelected[i].paymentDistribution * 100) /
            100;
        }
      }
    },
    async deductRemainsToBeDeducted(index, value) {
      let roundValue = Math.round(value * 100) / 100;
      if (roundValue < 0) {
        this.documentsSelected[index].paymentDistribution = 0;
      } else if (roundValue > this.documentsSelected[index].remainingToPayTtc) {
        let number =
          Math.round(this.documentsSelected[index].remainingToPayTtc * 100) /
          100;
        this.documentsSelected[index].paymentDistribution = number;
        this.key++;
      }

      await this.calculateRemainsToBeDeducted();
    },
    calculateRemainsToBeDeducted() {
      this.remainsToBeDeducted =
        this.initialRemainsToBeDeducted -
        this.documentsSelected.reduce((total, document) => {
          return (total +=
            Math.round(document.paymentDistribution * 100) / 100);
        }, 0);
    },
  },
  computed: {
    ...mapGetters([
      "isLoadingPayment",
      "isLoadingCompany",
      "companiesList",
      "isLoadingPaymentMethodsList",
      "paymentMethodsList",
      "isLoadingBankAccountsList",
      "bankAccountsList",
      "workspaceSelected",
      "isLoadingInstitutionsList",
      "institutionsList",
      "institutionSelected",
      "isCreatingPayment",
      "isUpdatingPayment",
    ]),
    gridData() {
      return this.documentListForDeduction;
    },
    datePicker: {
      get() {
        return {
          startDate: dayjs(this.paymentForm.date),
          endDate: dayjs(this.paymentForm.date),
        };
      },
      set(value) {
        this.paymentForm.date = value.startDate;
      },
    },
    datePickerRemittedBankDate: {
      get() {
        return {
          startDate:
            this.paymentForm.remittedBankDate == null
              ? null
              : dayjs(this.paymentForm.remittedBankDate),
          endDate:
            this.paymentForm.remittedBankDate == null
              ? null
              : dayjs(this.paymentForm.remittedBankDate),
        };
      },
      set(value) {
        this.paymentForm.remittedBankDate = value.startDate;
      },
    },
    selectDocumentLabel() {
      if (this.paymentForm.paymentNature == 1) {
        return "Sélectionner les avoirs à solder";
      } else if (this.paymentForm.paymentNature == 0) {
        return "Sélectionner les factures à solder";
      }
    },
    companiesListByNature() {
      return this.companiesList.filter(
        (el) => el.companyType.id == 5 || el.companyType.id == 7
      );
    },
  },
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    Autocomplete,
    DateRangePicker,
    BFormRadio,
  },
  directives: {
    Ripple,
    mask,
  },
};
</script>

<style lang="scss">
.array-distribution-payment {
  border-radius: 3px;
  border: solid 1px #d8d6de;

  .array-header {
    border-bottom: solid 1px #d8d6de;
    margin: -12px;
  }

  .array-content:not(:last-child) {
    border-bottom: solid 1px #d8d6de;
  }

  .row .array-cell:not(:last-child) {
    border-right: solid 1px #d8d6de;
    box-sizing: border-box;
  }
}

#formCreatePayment.form-control:disabled {
  border: 1px solid #d8d6de;
  background-color: #efefef;
}

.inline-spacing {
  margin-top: 0.2857rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.content-scrollable-sticky {
  // display: grid;
  // grid-template-columns: min-content 1fr;
  position: relative;

  .content-scrollable-sticky-nav {
    white-space: nowrap;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li a {
        display: block;
        padding: 1rem 0rem;
        color: white;
        text-decoration: none;
      }

      li a.current {
        background: black;
      }
    }
  }
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  .content-scrollable-sticky-nav ul {
    position: sticky;
    top: 0;
  }
}
</style>
